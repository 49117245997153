<script>
    import { Link } from '@inertiajs/svelte';

    export let message = 'The requested auction could not be found.';
</script>

<div class="flex min-h-screen flex-col items-center justify-center">
    <div class="w-full max-w-xl overflow-hidden rounded-lg bg-white shadow-md">
        <div class="p-6">
            <h2 class="mb-4 text-2xl font-bold text-gray-800">Auction Not Found</h2>
            <p class="mb-6 text-gray-600">{message}</p>
            <div class="flex items-center justify-between">
                <Link href="/" class="text-blue-500 transition-colors hover:text-blue-600">&larr; Back to Home</Link>
                <Link
                    href="/market/my/auctions"
                    class="rounded bg-blue-500 px-4 py-2 font-bold text-white transition-colors hover:bg-blue-600"
                >
                    View All Auctions
                </Link>
            </div>
        </div>
    </div>
</div>
